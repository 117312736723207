import {Form, Button, Input, ErrorIcon} from '@fluentui/react-northstar'
import * as microsoftTeams from "@microsoft/teams-js"
import {AxiosError} from 'axios'
import React, {useContext, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {getRequest, postRequest} from '../../utils/api'
import {
    CreateVoteInterface,
    MessageroundInterface,
    OptionInterface,
    QuizDetailsInterface,
    VoteErrorInterface
} from '../../utils/Interfaces'

import {SendstepsUserContext} from '../../utils/UserContext'
import {QuestionTypePopup} from './QuestionTypePopup'
import {MultipleChoice} from './QuestionTypes/MultipleChoice'
import {OpenEnded} from './QuestionTypes/OpenEnded'
import {Quiz} from './QuestionTypes/Quiz'

interface SaveQuizRequestInterface {
    presentationId: string
    quizTitle: string
    votes: string
    messagerounds: string
}

const CreatePresentationModule = () => {

    const {sendstepsUser} = useContext(SendstepsUserContext)
    const token = sendstepsUser ? sendstepsUser.token : ''

    const {quizId} = useParams<{ quizId: string | undefined }>()

    const [quizTitle, setQuizTitle] = useState('')
    const [quizTitleError, setQuizTitleError] = useState(false)

    const generateId = () => 'GEN_' + Math.random().toString(36).substr(2, 10)

    const initializeVotes = () => {
        if (quizId) {
            getRequest(`/quiz/details/${quizId}`, token, (res: QuizDetailsInterface) => {
                setQuizTitle(res.presentation.name)
                const voteKeys = Object.keys(res.votes)
                const initialVotes = voteKeys.map((voteKey: string) => ({
                    ...res.votes[Number(voteKey)],
                    options: res.voteAnswers[Number(voteKey)]
                }))
                const messageroundsKeys = Object.keys(res.messagerounds)
                const initialMessagerounds: MessageroundInterface[] = messageroundsKeys.map((msgrndKey: string) => res.messagerounds[Number(msgrndKey)])
                setVotes([...initialMessagerounds, ...initialVotes].sort((a, b) => a.slideIndex > b.slideIndex ? 1 : -1))
            }, () => {
                //error handling
            })
        }
    }

    const [votes, setVotes] = useState<(CreateVoteInterface | MessageroundInterface)[]>([])
    const [voteErrors, setVoteErrors] = useState<VoteErrorInterface[]>()

    const setQuizTitleField = (event: React.SyntheticEvent<HTMLElement, Event>) => {
        (event.target as HTMLInputElement).value !== '' && setQuizTitleError(false)
        setQuizTitle((event.target as HTMLInputElement).value)
    }

    const addQuestion = (type: string) => {
        let initialVote = {
            id: generateId(),
            title: '',
            votesCounterPresent: 0,
            dynamicTimerLimit: 0,
            slideIndex: 1,
            points: 500,
            isQuizVote: 1,
            nrOfVotes: 1,
            nrOfVotesArePerAnswer: 0,
            opened: 0,
            options: [
                {
                    id: generateId(),
                    answer: '',
                    correctAnswer: false
                },
                {
                    id: generateId(),
                    answer: '',
                    correctAnswer: false
                }
            ]
        }

        let initialMessageround = {
            id: generateId(),
            slideIndex: 1,
            title: '',
            active: 1,
            nrOfMessagesAllowed: 1,
            isWordcloudQuestion: 0,
            hasUpvoting: 0,
            nrOfMessageShapes: 1,
            opened: 0
        }
        if (type === 'quiz') setVotes([...votes, initialVote])
        else if (type === 'multiple') {
            initialVote.isQuizVote = 0
            setVotes([...votes, initialVote])
        } else if (type === 'openEnded')
            setVotes([...votes, initialMessageround])
    }

    const validateForm = () => {
        let voteErrorsBuffer: VoteErrorInterface[] = []
        const questionMessage = 'Please enter a question no longer than 255 characters'
        const optionMessage = 'Options cannot be empty'
        const noCorrectAnswerMessage = 'Please mark at least one option as correct'

        let hasErrors = false

        quizTitle === '' && setQuizTitleError(true) && (hasErrors = true)

        const initialVoteErrors: VoteErrorInterface = {
            error: false,
            messages: []
        }

        const pushErrorMessage = (index: number, errorMessage: string) => {
            !voteErrorsBuffer[index].messages.includes(errorMessage) && voteErrorsBuffer[index].messages.push(errorMessage)
            voteErrorsBuffer[index].error = true
            hasErrors = true
        }

        votes.forEach((vote: CreateVoteInterface | MessageroundInterface, index: number) => {
            voteErrorsBuffer.push(JSON.parse(JSON.stringify(initialVoteErrors)))
            vote.title === '' && pushErrorMessage(index, questionMessage)
            if ('options' in vote) {
                let hasCorrectAnswer = false
                vote.options.forEach((option: OptionInterface) => {
                    option.answer === '' && pushErrorMessage(index, optionMessage)
                    vote.isQuizVote ? (option.correctAnswer && (hasCorrectAnswer = true)) : (hasCorrectAnswer = true)
                })
                !hasCorrectAnswer && pushErrorMessage(index, noCorrectAnswerMessage)
            }
        })
        setVoteErrors(voteErrorsBuffer)
        return hasErrors
    }

    const submitTask = () => {
        if (!validateForm()) {
            let toBeSubmittedMessagerounds: MessageroundInterface[] = []
            let toBeSubmittedVotes: CreateVoteInterface[] = []
            votes.forEach((vote: CreateVoteInterface | MessageroundInterface, index: number) => {
                vote.slideIndex = index + 1
                'isQuizVote' in vote ?
                    toBeSubmittedVotes.push(vote) :
                    toBeSubmittedMessagerounds.push(vote)
            })
            const body: SaveQuizRequestInterface = {
                presentationId: quizId ? quizId : generateId(),
                quizTitle,
                votes: JSON.stringify(toBeSubmittedVotes),
                messagerounds: JSON.stringify(toBeSubmittedMessagerounds)
            }
            postRequest('/quiz/save-quiz', body,
                (res: any) => {
                    !res.error && microsoftTeams.tasks.submitTask('')
                },
                (error: AxiosError) => {
                    console.log(error.message)
                }, token)
        }
    }

    useEffect(initializeVotes, [])
    useEffect(() => microsoftTeams.appInitialization.notifySuccess(), [])

    const updateVote = (updatedVote: (MessageroundInterface | CreateVoteInterface | undefined), index: number) => {
        let votesBuffer = [...votes]
        updatedVote ?
            votesBuffer[index] = updatedVote :
            votesBuffer.splice(index, 1)
        setVotes(votesBuffer)
    }

    const conditionalFormValidation = () => {
        voteErrors && validateForm()
    }
    useEffect(conditionalFormValidation, [votes])

    return (
        <div className='CreateQuizModal'>
            <h1>Create a Sendsteps</h1>
            <Form className='CreateQuizModal__Form'>
                <label className='CreateQuizModal__QuestionLabel'>
                    <p>Sendsteps title</p>
                    <Input
                        value={quizTitle}
                        onChange={setQuizTitleField}
                        placeholder='Enter sendsteps title'
                        type='text'/>
                    {
                        quizTitleError &&
                        <div className='CreateQuizModal__Errors'>
                            <div className='CreateQuizModal__Error'>
                                <ErrorIcon/>
                                <p className='CreateQuizModal__Error--Message'>Please enter quiz title</p>
                            </div>
                        </div>
                    }
                </label>
                {
                    votes.map((vote: CreateVoteInterface | MessageroundInterface, index: number) =>
                        'options' in vote ?
                            vote.isQuizVote ?
                                <Quiz
                                    key={index}
                                    index={index}
                                    quiz={vote}
                                    voteErrors={voteErrors && voteErrors[index]}
                                    updateVote={(vote: CreateVoteInterface) => updateVote(vote, index)}/> :
                                <MultipleChoice
                                    key={index}
                                    index={index}
                                    multipleChoice={vote}
                                    voteErrors={voteErrors && voteErrors[index]}
                                    updateVote={(vote: CreateVoteInterface) => updateVote(vote, index)}/> :
                            <OpenEnded
                                key={index}
                                index={index}
                                openEnded={vote}
                                voteErrors={voteErrors && voteErrors[index]}
                                updateVote={(vote: MessageroundInterface) => updateVote(vote, index)}/>)
                }


                <div className='CreateQuizModal__Buttons'>
                    <QuestionTypePopup addVote={(type: string) => addQuestion(type)}/>
                    <span
                        data-eltype="button"
                        data-elaction="save"
                        data-elscreen="create_presentation_modal"
                        data-elposition="footer"
                    >
                        <Button
                            onClick={submitTask}
                            className='CreateQuizModal__Button'
                            primary
                        >Save</Button>
                    </span>
                </div>
            </Form>
        </div>
    )
}

export {CreatePresentationModule}
