import { Button, Popup } from '@fluentui/react-northstar'
import React, { useState } from 'react'

import { mdiTrophy, mdiFormatListChecks, mdiChatProcessing } from '@mdi/js';
import { Icon } from '@mdi/react';

const QuestionTypePopup = (props: { addVote: (type: string) => void }) => {

    const [open, setOpen] = useState(false)

    const addVote = (type: string) => {
        setOpen(false)
        props.addVote(type)
    }

    const popupContent = (
        <div className='QuestionTypePopup'>
            <Button onClick={() => addVote('quiz')} primary> <Icon size={1} path={mdiTrophy} /> Quiz</Button>
            <Button onClick={() => addVote('multiple')} primary> <Icon size={1} path={mdiFormatListChecks} /> Multiple Choice</Button>
            <Button onClick={() => addVote('openEnded')} primary> <Icon size={1} path={mdiChatProcessing} /> Open Ended</Button>
        </div>
    )
    return (
        <Popup
            open={open}
            onOpenChange={(e, open) => open && open.open !== undefined && setOpen(open.open)}
            content={{
                content: popupContent,
                'aria-label': 'Quiz Type',
            }}
        >
            <Button className='CreateQuizModal__Button' content="Add Question" primary />
        </Popup>
    )
}

export { QuestionTypePopup }
