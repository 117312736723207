import {Button} from '@fluentui/react-northstar'
import React from 'react'
import {OptionInterface} from '../../../utils/Interfaces'
import {AnswerGroup} from './AnswerGroup'

const Answers = (props: { options: OptionInterface[], setOptions(options: OptionInterface[]): void, hideCorrectAnswer?: boolean }) => {

    const {options} = props

    const initialOption: OptionInterface = {
        id: 'GEN_' + Math.random().toString(36).substr(2, 10),
        answer: '',
        correctAnswer: false
    }

    const updateOption = (option: OptionInterface | undefined, index: number) => {
        let optionsBuffer = [...options]
        option ?
            optionsBuffer[index] = option :
            optionsBuffer.splice(index, 1)
        props.setOptions(optionsBuffer)
    }

    return (
        <div>
            {
                options.map((option: OptionInterface, index: number) =>
                    <AnswerGroup
                        hideCorrectAnswer={!!props.hideCorrectAnswer}
                        option={option}
                        key={index}
                        index={index}
                        removeDisabled={options.length < 3}
                        updateAnswer={(option: OptionInterface | undefined) => updateOption(option, index)}/>)
            }
            <span
                data-eltype="button"
                data-elaction="add_answer"
                data-elscreen="create_presentation_module"
                data-elposition="answer_group"
            >
                <Button
                    primary
                    className='CreateQuizModal__AddAnswer'
                    onClick={() => props.setOptions([...options, initialOption])}
                >+ Add Answer</Button>
            </span>
        </div>
    )
}

export {Answers}
