import { Button, TextArea } from '@fluentui/react-northstar'
import React, { useState } from 'react'
import { MessageroundUpdatedInterface } from '../../../../utils/Interfaces'

const ParticipantMessageround = (props: { messageround: MessageroundUpdatedInterface, submitMessageround(text: string): void, currentVoteSubmitted: {answers: any[], nrOfVotes: number } }) => {

    const [message, setMessage] = useState('')
    const { messageround, submitMessageround, currentVoteSubmitted } = props

    const voteLimitReached = currentVoteSubmitted.answers.length >= currentVoteSubmitted.nrOfVotes
    const voteLimitCounter = currentVoteSubmitted.nrOfVotes - currentVoteSubmitted.answers.length

    const submit = () => {
        if(message !== '')
         submitMessageround(message)
         setMessage('')
    }

    return (
        <div className='ParticipantModule__Vote'>
            <h1 className='ParticipantModule__Question'>{messageround.data[0].title}</h1>
            {
                !voteLimitReached ?
                <div>
                    {currentVoteSubmitted.nrOfVotes !== 99 && <p>{`You can vote ${voteLimitCounter} time${voteLimitCounter > 1 ? 's' : ''}`}</p> }
                    <div className='ParticipantModule__MessageroundInput'>
                        <TextArea
                            fluid
                            maxLength={160}
                            className='ParticipantModule__MessageroundInput--Text'
                            value={message}
                            onChange={(e) => setMessage((e.target as HTMLInputElement).value)}
                            placeholder='Enter your answer'
                        />
                        <span
                            data-eltype="button"
                            data-elaction="submit_messageround"
                            data-elscreen="side_panel_participant"
                            data-elposition="participant_messageround"
                        >
                            <Button
                                disabled={message === ''}
                                primary
                                className='ParticipantModule__MessageroundInput--Button'
                                onClick={()=>submit()}
                            >Send</Button>
                        </span>
                    </div>
                </div> :
                <div>
                    limit reached
                </div>
            }
        </div>
    )
}

export { ParticipantMessageround }
