import { ErrorIcon } from '@fluentui/react-northstar'
import React from 'react'
import { VoteErrorInterface } from '../../../utils/Interfaces'

const Errors = (props: { voteErrors: VoteErrorInterface }) => {

    const { voteErrors } = props

    return (
        <div className='CreateQuizModal__Errors'>
            {
                voteErrors.messages.map((message: string, errorIndex: number) =>
                    <div className='CreateQuizModal__Error' key={errorIndex}>
                        <ErrorIcon />
                        <p className='CreateQuizModal__Error--Message'>{message}</p>
                    </div>)
            }
        </div>
    )
}

export { Errors }