import React, { useEffect, useState } from 'react'
import * as microsoftTeams from "@microsoft/teams-js"
import { PresenterModule } from './Presenter/PresenterModule'
import { PresenterQuiz } from './Presenter/PresenterQuiz'
import { useParams } from 'react-router-dom'
import { ParticipantModule } from './Participant/ParticipantModule'
import { useContext } from 'react'
import { SendstepsUserContext } from '../../utils/UserContext'
import { sendshakeRequest } from '../../utils/api'
import { AxiosError } from 'axios'

interface startedSessionInterface {
    quizId: number
    sessionData: {
        sessionId: string
        sessionRunId: string
        responseCode: string
    }
}

interface ParamsInterface {
    sessionId: string
    sessionRunId: string
    responseCode: string
    presenterId: string
}

const ChannelTabSidePanel = (props: { context: microsoftTeams.Context }) => {

    const { presenterId } = useParams<ParamsInterface>()

    const isPresenter = props.context.userObjectId === presenterId

    const [startedSession, setStartedSession] = useState<startedSessionInterface | null>()

    const {sendstepsUser} = useContext(SendstepsUserContext)

    useEffect(() => microsoftTeams.appInitialization.notifySuccess(), [])

    const stopQuiz = () => {
        setStartedSession(null)
    }

    const closeRunningSession = () => {
        if (sendstepsUser) {
          const sendshakeData = {
            request: encodeURI('CloseRunningSession'),
            token: encodeURI(sendstepsUser.token)
          }
          sendshakeRequest(sendshakeData, () => {

          }, () => {

          })
        }
      }

    const notifyAll = () => {
        if (sendstepsUser) {
          const sendshakeData = {
            request: encodeURI('NotifyAll'),
            token: encodeURI(sendstepsUser.token)
          }
          sendshakeRequest(sendshakeData, () => {

          }, () => {

          })
        }
      }

    const startSession = (quizId: number) => {
        if (sendstepsUser) {
            // first close the running
            const closeRunningSessionData = {
              request: encodeURI('CloseRunningSession'),
              token: encodeURI(sendstepsUser.token)
            }
            sendshakeRequest(closeRunningSessionData, () => {
              // then get the session
              const getSessionForLoginTokenData = {
                request: encodeURI('GetSessionForLoginToken'),
                token: encodeURI(sendstepsUser.token),
                addin_name: encodeURI('Sendsteps-Beta'),
                addin_uid: encodeURI(''),
                machine_uid: encodeURI(''),
                client: 'msteams',
                notifyAll: false
              }

              sendshakeRequest(getSessionForLoginTokenData, (res: string[]) => {
                const sessionData = {
                  sessionId: res[1],
                  sessionRunId: res[2],
                  responseCode: res[11]
                }
                if(sessionData.sessionRunId !== '0'){
                    const session = {
                        quizId,
                        sessionData
                    }

                    notifyAll()
                    setStartedSession(session)


                } else startSession(quizId)
              }, (error: AxiosError) => {
                console.log(error)
              })

            }, () => {
              // ignore error
            })
        }
      }

    if (isPresenter) return (
        startedSession ?
            <PresenterQuiz stopQuiz={() => stopQuiz()} sessionData={startedSession} /> :
            <PresenterModule startPresentation={startSession} />
    )
    else return (<ParticipantModule
                    context={props.context} />)
}

export { ChannelTabSidePanel }