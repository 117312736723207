import React, { useEffect } from 'react'
import { Button, Flex, Header } from '@fluentui/react-northstar'
import * as microsoftTeams from "@microsoft/teams-js"

const DeleteQuizModal = () => {

    const submitTask = (willDelete: string) => microsoftTeams.tasks.submitTask(willDelete)

    useEffect(() => microsoftTeams.appInitialization.notifySuccess(), [])

    return (
        <div className='DeleteQuizModal'>
            <Header
            className='DeleteQuizModal__Header'
                align='center'
                as='h2'>
                Are you sure you want to remove this presentation?</Header>
            <Flex
                className='DeleteQuizModal__Buttons'
                gap='gap.small'
                space='between'>
                <Button
                    fluid
                    onClick={() => submitTask('cancel')}>
                    Cancel</Button>
                <Button
                    primary fluid
                    onClick={() => submitTask('delete')}>
                    Delete</Button>
            </Flex>
        </div>
    )
}
export { DeleteQuizModal }