import { Input } from '@fluentui/react-northstar'
import React from 'react'

const QuestionLabel = (props: {value: string, updateVoteTitle(value: string): void }) => {

    return (
        <label className='CreateQuizModal__QuestionLabel'>
            <Input
                value={props.value}
                onChange={(e) => props.updateVoteTitle((e.target as HTMLInputElement).value)}
                placeholder='Enter your question'
                type='text' />
        </label>
    )
}

export { QuestionLabel }