import React, {useState, useEffect} from 'react'
import * as microsoftTeams from "@microsoft/teams-js"
import {postRequest} from '../../../utils/api'
import {AxiosError} from 'axios'
import {
    MessageroundUpdatedInterface,
    ParticipantInitInfo,
    SendstepsParticipantInterface,
    VoteUpdatedInterface,
    VoteResultsInterface,
    ScoreResultsLeaderboardInterface,
    ScoreResultsInterface,
    VoteAnswerInterface,
    AnsweredVoteType
} from '../../../utils/Interfaces'
import {NicknameInput} from '../../NicknameInput'
import {ParticipantVote} from './QuestionViews/ParticipantVote'
import {ParticipantMessageround} from './QuestionViews/ParticipantMessageround'
import GoogleTagManager from '../../GoogleTagManager'
interface SubmittedAnswerInterface {
    answers: any[]
    nrOfVotes: number
}

const ParticipantModule = (props: { context: microsoftTeams.Context }) => {

    const SHOW_DEBUG_INFO = false

    const io = require('socket.io-client')

    const [sendstepsParticipant, setSendstepsParticipant] = useState<SendstepsParticipantInterface>()
    const [participantInitInfo, setParticipantInitInfo] = useState<ParticipantInitInfo>()
    const [nicknameConfigured, setNicknameConfigured] = useState(false)
    const [defaultNickname, setDefaultNickname] = useState( props.context.userPrincipalName ? props.context.userPrincipalName.split('@')[0] : '' )

    const [currentVote, setCurrentVote] = useState<VoteUpdatedInterface | MessageroundUpdatedInterface>()
    const [currentVoteSubmitted, setCurrentVoteSubmitted] = useState<SubmittedAnswerInterface>({
        answers: [],
        nrOfVotes: 1
    })
    const [answeredVotes, setAnsweredVotes] = useState<AnsweredVoteType[]>([])
    const [results, setResults] = useState<ScoreResultsInterface>()
    const [loadingFinished, setLoadingFinished] = useState(false)
    const [loadingError, setLoadingError] = useState(false)

    const [voteResults, setVoteResults] = useState<VoteResultsInterface>();

    const initSocket = () => {
        if (sendstepsParticipant) {
            const socket = io(process.env.REACT_APP_SOCKET_URL, {
                reconnection: true,
                reconnectionDelay: 2000,
                reconnectionDelayMax: 5000,
                timeout: 20e3,
                reconnectionAttempts: 999,
                transports: ['websocket']
            })

            socket.on('connect', () => {
                const socketData = {
                    socketId: socket.id,
                    sessionId: sendstepsParticipant.sessionId,
                    token: sendstepsParticipant.token,
                    participantId: sendstepsParticipant.user.id
                }
                socket.emit('session:subscribe_client', socketData)
            })

            socket.on('vote:updated', (data: VoteUpdatedInterface) => {
                if (data.data[0].opened) {
                    setVoteResults(undefined)
                    setCurrentVoteSubmitted({answers: [], nrOfVotes: data.data[0].nrOfVotes})
                    setCurrentVote(data)
                }
            })

            socket.on('messageRound:updated', (data: MessageroundUpdatedInterface) => {
                if (data.data[0].opened) {
                    setCurrentVote(data)
                    setCurrentVoteSubmitted({
                        answers: [],
                        nrOfVotes: data.data[0].nrOfMessagesAllowed !== 0 ? data.data[0].nrOfMessagesAllowed : 99
                    })
                }
            })

            socket.on('presentation:clear_results', () => {
                setAnsweredVotes([])
                setResults(undefined)
            })

            socket.on('session:stop', () => {
                setAnsweredVotes([])
                setResults(undefined)
            })

            socket.on('participant:scoreUpdate', (res: any) => {
                setCurrentVote(undefined)
                setResults(res)
            })

            socket.on('vote:results', (res: any) => {
                setVoteResults( res.data )
            })

            socket.on('session:start', () => {
                // trigger the questions again (nickname only for now)
                setNicknameConfigured(false)
            })

            return () => socket.disconnect()
        }
    }

    const participantLogin = () => {
        const formData = new URLSearchParams()
        formData.append('teamsMeetingId', props.context.meetingId || '')
        formData.append('userId', props.context.userObjectId || '')
        postRequest('/audience/login', formData.toString(), (res: SendstepsParticipantInterface) => {
            setSendstepsParticipant(res)
        }, (error: AxiosError) => {
            setLoadingError(true)
        })
    }

    const getCurrentOpenVote = (index: number) : VoteUpdatedInterface|undefined => {
        if (sendstepsParticipant && participantInitInfo) {

            // Get the answers from the init data
            let currentAnswers: VoteAnswerInterface[] = []

            // Reconstruct the answers for the vote
            const answerKeys = Object.keys(participantInitInfo.votes[index].answers);
            answerKeys.forEach(val => currentAnswers.push(participantInitInfo.votes[index].answers[val]))

            // Reconstuct the vote object
            let currentVote: VoteUpdatedInterface = {
                sessionId: sendstepsParticipant.sessionId,
                apiTime: null,
                answers: currentAnswers,
                data: []
            }

            currentVote.data[0] = {
                ...participantInitInfo.votes[index]
            }

            return currentVote
        }
        return undefined
    }

    const getCurrentOpenMessageRound = (index: number) : MessageroundUpdatedInterface|undefined => {
        if (sendstepsParticipant && participantInitInfo) {

            // Reconstuct the messageround object
            let currentMessageround: MessageroundUpdatedInterface = {
                sessionId: sendstepsParticipant.sessionId,
                apiTime: null,
                answers: [],
                data: []
            }
            currentMessageround.data[0] = {
                ...participantInitInfo.messageRounds[index]
            }

            return currentMessageround
        }
        return undefined
    }

    const setCurrentVoteAnswers = () => {

        let newAnsweredVotes: AnsweredVoteType[] = [];

        if (sendstepsParticipant && participantInitInfo && participantInitInfo.participantVotes) {

            const voteKeys = Object.keys(participantInitInfo.participantVotes)

            voteKeys.forEach( (key) => {
                if ( participantInitInfo.participantVotes && participantInitInfo.participantVotes[parseInt(key)].participantId === sendstepsParticipant.user.id ) {

                    if ( participantInitInfo.participantVotes[parseInt(key)].text ) {

                        const answerCode = participantInitInfo.participantVotes[parseInt(key)].text
                        const answerVoteId = participantInitInfo.participantVotes[parseInt(key)].voteId

                        if ( participantInitInfo.votes[answerVoteId] ) {
                            newAnsweredVotes.push( {
                                    voteId: participantInitInfo.participantVotes[parseInt(key)].voteId,
                                    answerIds: [participantInitInfo.votes[answerVoteId].answers[answerCode].id]
                            })
                        }
                    }
                }
            })
            setAnsweredVotes( newAnsweredVotes )
        }
    }

    const setCurrentMessageRoundAnswers = () => {
        if (sendstepsParticipant && participantInitInfo) {

            if ( participantInitInfo.participantMessages ) {

                const messageKeys = Object.keys(participantInitInfo.participantMessages)

                messageKeys.forEach( (key) => {
                    if ( participantInitInfo.participantMessages && participantInitInfo.participantMessages[parseInt(key)].participantId === sendstepsParticipant.user.id ) {

                        if ( participantInitInfo.participantMessages[parseInt(key)].text ) {

                            const answer = participantInitInfo.participantMessages[parseInt(key)].text

                            setCurrentVoteSubmitted({
                                answers: [ answer ],
                                nrOfVotes: 99
                            })
                        }
                    }
                })
            }
        }
    }

    const checkForOpenQuestion = (initData: ParticipantInitInfo) => {
        //check if there is an open question and make this question the current one
        const voteKeys = Object.keys(initData.votes);
        const messageRoundKeys = Object.keys(initData.messageRounds);

        let voteKey = 0
        let messageRoundKey = 0

        voteKeys.forEach( (key) => {
            if ( initData.votes[parseInt(key)].opened === 1 ) {
                voteKey = parseInt(key)
            }
        })

        messageRoundKeys.forEach( (key) => {
            if ( initData.messageRounds[parseInt(key)].opened === 1 ) {
                messageRoundKey = parseInt(key)
            }
        })

        if ( voteKey !== 0 ) {
            setCurrentVote( getCurrentOpenVote(voteKey) )
        }
        if ( messageRoundKey !== 0 ) {
            setCurrentVote( getCurrentOpenMessageRound(messageRoundKey) )
        }

        // Reconstuct the given answers
        setCurrentMessageRoundAnswers()
        setCurrentVoteAnswers()
    }

    const participantInit = () => {
        if (sendstepsParticipant) {
            const formData = new URLSearchParams()
            formData.append('sessionId', sendstepsParticipant.sessionId.toString())
            const pToken = sendstepsParticipant.token
            postRequest('/audience/init', formData.toString(), (res: ParticipantInitInfo) => {
                setParticipantInitInfo(res)
                setLoadingFinished(true)

                if (res.participantInfo) {
                    if ( res.participantInfo[Number(Object.keys(res.participantInfo)[0])].value !== '') {
                        setNicknameConfigured(true)
                    }
                }

            }, (err: AxiosError) => {
                setLoadingError(true)
            }, pToken)
        }
    }

    const setParticipantInfo = (nickname: string) => {
        if (participantInitInfo) {
            setDefaultNickname(nickname)
            const pToken = sendstepsParticipant?.token
            let body: { [key: string]: any } = {answers: {}}
            const participantInfoKeys = Object.keys(participantInitInfo.participantInfoFields)
            participantInfoKeys.forEach((key: string) => participantInitInfo.participantInfoFields[Number(key)].data.quizIdentifier && (body.answers[key] = nickname))

            postRequest('/audience/participantinfo', body, (res: any) => {
                setNicknameConfigured(true)
            }, (err: AxiosError) => {

            }, pToken)
        }
    }

    const checkUpdatedVote = () => {
        if (currentVote && 'isQuizVote' in currentVote.data[0]) {
            const voteAnswered = answeredVotes.find(vote => vote.voteId === currentVote.data[0].id)
            voteAnswered ?
                setCurrentVoteSubmitted({
                    answers: [...voteAnswered.answerIds],
                    nrOfVotes: currentVote.data[0].nrOfVotes
                }) :
                setCurrentVoteSubmitted({answers: [], nrOfVotes: currentVote.data[0].nrOfVotes})
        }
    }

    const removeLoadingIndicator = () => {
        !loadingError && microsoftTeams.appInitialization.notifySuccess()
    }

    const checkForRunningOpenQuestion = () => {
        if (sendstepsParticipant && participantInitInfo) {
            checkForOpenQuestion(participantInitInfo)
        }
    }

    useEffect( () => {

        GoogleTagManager()

        participantLogin()

    }, [])

    useEffect(initSocket, [sendstepsParticipant])
    useEffect(participantInit, [sendstepsParticipant])
    useEffect(checkForRunningOpenQuestion, [participantInitInfo])
    useEffect(removeLoadingIndicator, [loadingFinished])
    useEffect(checkUpdatedVote, [currentVote])

    const submitVote = (index: number) => {
        if (currentVote && participantInitInfo && 'isQuizVote' in currentVote.data[0]) {
            const pToken = sendstepsParticipant?.token
            const submittedAnswer = currentVote.answers[index]
            const formData = new URLSearchParams()

            const voteId = currentVote.data[0].id
            const answerId = submittedAnswer.id

            formData.append('answerCode', submittedAnswer.answerCode)
            formData.append('answerId', answerId.toString())
            formData.append('sessionOwnerUserId', participantInitInfo.session.userId.toString())
            formData.append('voteId', voteId.toString())

            postRequest('/audience/vote_answer', formData.toString(), (res: any) => {
                setCurrentVoteSubmitted({
                    answers: [...currentVoteSubmitted.answers, answerId],
                    nrOfVotes: currentVoteSubmitted.nrOfVotes
                })
                const answeredVote = answeredVotes.find(answeredVote => answeredVote.voteId === voteId)
                if (answeredVote) {
                    setAnsweredVotes(answeredVotes.map(answeredVote =>
                        answeredVote.voteId === voteId ?
                            {voteId: voteId, answerIds: [...answeredVote.answerIds, answerId]} :
                            answeredVote))
                } else setAnsweredVotes([...answeredVotes, {voteId: voteId, answerIds: [answerId]}])
            }, () => {

            }, pToken)
        }
    }

    const submitMessageround = (text: string) => {
        if (currentVote && participantInitInfo && 'hasUpvoting' in currentVote.data[0]) {
            setCurrentVoteSubmitted({
                answers: [...currentVoteSubmitted.answers, text],
                nrOfVotes: currentVoteSubmitted.nrOfVotes
            })
            const pToken = sendstepsParticipant?.token
            const formData = new URLSearchParams()

            formData.append('commentTitle', '')
            formData.append('messageRoundId', currentVote.data[0].id.toString())
            formData.append('sessionOwnerUserId', participantInitInfo.session.userId.toString())
            formData.append('text', text)

            postRequest('/audience/messageround_message', formData.toString(), (res: any) => {

            }, () => {

            }, pToken)
        }
    }

    return (
        <div className='ParticipantModule'>
            {
                !nicknameConfigured ?

                    <NicknameInput
                        initialUsername={defaultNickname}
                        setNickname={(nickname: string) => setParticipantInfo(nickname)}
                    />

                :

                (currentVote && currentVote.data[0].opened) ?

                    'isQuizVote' in currentVote.data[0] ?
                        <ParticipantVote
                            currentVote={currentVote as VoteUpdatedInterface}
                            submitVote={submitVote}
                            currentVoteSubmitted={currentVoteSubmitted}
                            voteResults={voteResults}
                        />

                        :

                        <ParticipantMessageround
                            messageround={currentVote as MessageroundUpdatedInterface}
                            submitMessageround={submitMessageround}
                            currentVoteSubmitted={currentVoteSubmitted}
                        />

                :

                results ?
                    <div className='ParticipantModule__Rank'>

                        <div>
                            <p>Your rank:</p>
                            <p>{results.data.rank}</p>
                        </div>
                        <div>
                            <p>Total points:</p>
                            <p>{results.data.scores.points}</p>
                        </div>

                        <div className='ParticipantModule__Rank--LeaderboardTitle'>
                            <p>Leaderboard</p>
                        </div>
                        <div className='ParticipantModule__Rank--Leaderboard'>
                            <p>Nickname</p>
                            <p>Points</p>
                        </div>
                        { results.data.leaderBoard.map((leaderBoard: ScoreResultsLeaderboardInterface, index: number) =>
                            <div key={index} className='ParticipantModule__Rank--Leaderboard'>
                                <p>{`${index + 1}. ${leaderBoard.name}`}</p>
                                <p>{`${leaderBoard.points}`}</p>
                            </div>)
                        }
                    </div>

                :

                    <div className='ParticipantModule__Ready'>
                        <p>You are all set!</p>
                        <p>When the presenter asks a question, it shows here.</p>
                    </div>
            }
            { SHOW_DEBUG_INFO &&
                <>
                    <div className='ParticipantModule__Debug'>
                        <b>sendstepsParticipant</b>
                        <pre>
                            { sendstepsParticipant && JSON.stringify(sendstepsParticipant, undefined, 2) }
                            { !sendstepsParticipant && <>No sendstepsParticipant</>}

                        </pre>
                    </div>
                    <div className='ParticipantModule__Debug'>
                        <b>Nickname</b>
                        <pre>
                            { nicknameConfigured && <>Nickname configured {defaultNickname}</> }
                            { !nicknameConfigured && <>No nickname configured</>}
                        </pre>
                    </div>

                    <div className='ParticipantModule__Debug'>
                        <b>currentVoteSubmitted</b>
                        <pre>
                            { currentVoteSubmitted && JSON.stringify(currentVoteSubmitted, undefined, 2) }
                            { !currentVoteSubmitted && <>No currentVoteSubmitted</>}
                        </pre>
                    </div>

                    <div className='ParticipantModule__Debug'>
                        <b>currentVote</b>
                        <pre>
                            { currentVote && JSON.stringify(currentVote, undefined, 2) }
                            { !currentVote && <>No current vote</>}
                        </pre>
                    </div>

                    <div className='ParticipantModule__Debug'>
                        <b>answeredVotes</b>
                        <pre>
                            { answeredVotes && JSON.stringify(answeredVotes, undefined, 2) }
                            { !answeredVotes && <>No answeredVotes</>}
                        </pre>
                    </div>

                    <div className='ParticipantModule__Debug'>
                        <b>participantInitInfo</b>
                        <pre>
                            { participantInitInfo && JSON.stringify(participantInitInfo, undefined, 2) }
                            { !participantInitInfo && <>No participantInitInfo</>}
                        </pre>
                    </div>
                </>
            }
        </div>
    )
}

export {ParticipantModule}
