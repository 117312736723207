import { mdiChatProcessing } from '@mdi/js'
import React from 'react'
import { MessageroundInterface, VoteErrorInterface } from '../../../utils/Interfaces'
import { DividerGroup } from '../Fields/DividerGroup'
import { Errors } from '../Fields/Errors'
import { NrOfMessagesAllowed } from '../Fields/NrOfMessagesAllowed'
import { QuestionLabel } from '../Fields/QuestionLabel'

const OpenEnded = (props: { openEnded: MessageroundInterface, voteErrors: VoteErrorInterface | undefined, index: number, updateVote(openEnded: MessageroundInterface | undefined): void }) => {

    const { openEnded, voteErrors, updateVote } = props

    return (
        <div className='CreateQuizModal__Vote'>
            <DividerGroup index={props.index} deleteVote={() => updateVote(undefined)} iconPath={mdiChatProcessing} />
            {voteErrors && <Errors voteErrors={voteErrors} />}
            <QuestionLabel value={openEnded.title} updateVoteTitle={(value: string) => updateVote({ ...openEnded, title: value })} />
            <NrOfMessagesAllowed nrOfMessagesAllowed={openEnded.nrOfMessagesAllowed} setNrOfMessagesAllowed={(nrOfMessagesAllowed: number)=>props.updateVote({...openEnded, nrOfMessagesAllowed: nrOfMessagesAllowed})}/>
        </div>
    )
}

export { OpenEnded }