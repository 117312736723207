import { Divider, TrashCanIcon } from '@fluentui/react-northstar'
import Icon from '@mdi/react'
import React from 'react'

const DividerGroup = (props: { index: number, deleteVote(): void, iconPath: string }) => {

    return (
        <div className='CreateQuizModal__DividerGroup'>
            <Divider
                className='CreateQuizModal__DividerGroup--Divider'
                size={4}>
                    <div className='DividerContent'>
                        <Icon size={.8} path={props.iconPath}/> 
                        <p>{`Question ${props.index + 1}`}</p>
                    </div>
                </Divider>
            <TrashCanIcon
                className='CreateQuizModal__DividerGroup--TrashIcon'
                onClick={() => props.deleteVote()} />
        </div>
    )
}

export { DividerGroup }