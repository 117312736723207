import { CheckmarkCircleIcon, Input, Tooltip, TrashCanIcon} from '@fluentui/react-northstar'
import React from 'react'
import { OptionInterface } from '../../../utils/Interfaces'

const AnswerGroup = (props: { option: OptionInterface, updateAnswer(option: OptionInterface | undefined): void, removeDisabled: boolean, index: number, hideCorrectAnswer?: boolean}) => {

    const { option, removeDisabled, index, hideCorrectAnswer} = props

    return (
        <div className='CreateQuizModal__AnswerGroup'>
            {
                !hideCorrectAnswer &&
                <Tooltip content={`${option.correctAnswer ? 'Correct' : 'Incorrect'} answer (Click to change)`} trigger={
                    <span
                        data-eltype="button"
                        data-elaction="start_presentation"
                        data-elscreen="side_panel"
                        data-elposition="presentation_card"
                    >
                        <CheckmarkCircleIcon
                            onClick={() => props.updateAnswer({ ...option, correctAnswer: !option.correctAnswer })}
                            style={option.correctAnswer ? { color: '#81C784' } : {}}
                            className='CreateQuizModal__AnswerGroup--CorrectAnswer'
                            size='larger' outline
                        />
                    </span>
                } />
            }
            <Input
                value={option.answer}
                onChange={(e) => props.updateAnswer({...option, answer: (e.target as HTMLInputElement).value})}
                placeholder={`Answer ${index + 1}`}
                type='text' />
            <TrashCanIcon
                className='CreateQuizModal__AnswerGroup--DeleteIcon'
                onClick={() => !removeDisabled && props.updateAnswer(undefined)} disabled={removeDisabled} />
        </div>
    )
}

export { AnswerGroup }
