import {Button, Form, Input} from '@fluentui/react-northstar'
import React, {useState} from 'react'

const NicknameInput = (props: { initialUsername: string, setNickname: (nickname: string) => void }) => {

    const [nickname, setNickname] = useState(props.initialUsername)
    const [nicknameError, setNicknameError] = useState(false)

    const setNicknameField = (event: React.SyntheticEvent<HTMLElement, Event>) => {
        (event.target as HTMLInputElement).value === '' && setNicknameError(true)
        setNickname((event.target as HTMLInputElement).value)
    }

    return (
        <div className='ParticipantModule__NicknameInput'>
            What is your nickname
            <Form
                onSubmit={() => props.setNickname(nickname)}>
                <Input
                    required
                    error={nicknameError}
                    value={nickname}
                    onChange={setNicknameField}
                    placeholder='Enter Nickname'
                />
                <span
                    data-eltype="button"
                    data-elaction="submit_nickname"
                    data-elscreen="side_panel_participant"
                    data-elposition="nickname_panel"
                >
                    <Button
                        content='Submit'
                        primary
                    />
                </span>
            </Form>
        </div>
    )
}

export {NicknameInput}
