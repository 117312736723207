import React, { useContext, useEffect, useState } from 'react'
import * as microsoftTeams from "@microsoft/teams-js"
import { Button } from '@fluentui/react-northstar'
import { getRequest, postRequest } from '../../utils/api'
import { QuizCard } from '../QuizCard'
import { QuizInterface } from '../../utils/Interfaces'
import { SendstepsUserContext } from '../../utils/UserContext'
import { Header } from '../Header'
import { AuthStart } from '../Auth/AuthStart'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'

const ChannelTabContent = (props: { context: microsoftTeams.Context }) => {

    const { sendstepsUser, updateSendstepsUser } = useContext(SendstepsUserContext)

    const token = sendstepsUser && sendstepsUser.token

    const { presenterId } = useParams<{ presenterId: string }>()

    const isPresenter = props.context.userObjectId === presenterId

    const [quizes, setQuizes] = useState<QuizInterface[]>([])

    const getQuizes = () => {
        if (token) {
            getRequest('/quiz/quizlist', token, (response: QuizInterface[]) => {
                setQuizes(response.reverse())
            }, () => {

            })
        }
    }

    const removeQuiz = (presentationId: number) => {
        if (token) {
            postRequest('/quiz/remove-quiz', {
                presentationId
            }, (res: any) => {
                getQuizes()
            }, (err: AxiosError) => {
                console.log(err)
            }, token)
        }
    }

    const removeQuizHandler = (quizId: number) => {
        microsoftTeams.tasks.startTask(removeQuizTask, (err: string, willDelete: string) => willDelete === 'delete' && removeQuiz(quizId))
    }

    const editQuizHandler = (quizId: number) => {
        const editQuizTask = {
            title: 'Edit quiz',
            height: 700,
            width: 1000,
            url: `${window.location.origin}/create-quiz/${quizId}`,
        }
        microsoftTeams.tasks.startTask(editQuizTask)
    }

    useEffect(getQuizes, [sendstepsUser])
    useEffect(() => microsoftTeams.appInitialization.notifySuccess(), [])

    const createQuizTask = {
        title: 'Create Sendsteps',
        height: 700,
        width: 1000,
        url: `${window.location.origin}/create-quiz`,
    }

    const removeQuizTask = {
        title: 'Remove Sendsteps',
        height: 300,
        width: 500,
        url: `${window.location.origin}/remove-quiz`,

    }

    if (isPresenter) return (
        sendstepsUser ?
            <div className='ChannelTabContent'>
                <Header user={sendstepsUser} logout={() => updateSendstepsUser(undefined)} />
                <span
                    data-eltype="button"
                    data-elaction="create_sendsteps"
                    data-elscreen="dashboard"
                    data-elposition="top_left"
                >
                    <Button
                        onClick={() => microsoftTeams.tasks.startTask(createQuizTask, getQuizes)}
                        className='ChannelTabContent__CreateButton'
                        primary
                    >
                        Create Sendsteps
                    </Button>
                </span>
                <p className='ChannelTabContent__MyQuizes'>My Sendsteps</p>
                <div className='ChannelTabContent__Grid'>
                    {
                        quizes ?
                            quizes.map((quiz: QuizInterface, index: number) =>
                                <QuizCard
                                    quiz={quiz}
                                    key={index}
                                    removeQuiz={() => removeQuizHandler(quiz.id)}
                                    editQuiz={() => editQuizHandler(quiz.id)} />) :
                            <p>You currently have no available quizes</p>
                    }
                </div>
            </div> :
            <AuthStart />
    )
    else return (
        <div>
            you are not presenter
        </div>
    )
}

export { ChannelTabContent }
