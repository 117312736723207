import React, { useState } from 'react'
import * as microsoftTeams from '@microsoft/teams-js'
import { Flex, Input, Button, Checkbox, Provider, teamsTheme } from '@fluentui/react-northstar'
import logoFull from '../../assets/sendsteps-logo-full.png'
import { AxiosError } from 'axios'
import { getRequest, postRequest } from '../../utils/api'

const AuthRegister = () => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [pass, setPass] = useState('')
    const [rememberMe, setRememberMe] = useState(false)
    const [isTermsAccepted, setIsTermsAccepted] = useState(false)
    const [isPending, setIsPending] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')

    const handlingErrorResponse = (errorRes: any) => {
        let resHolder: string = ''
        for (const property in errorRes) {
            resHolder += errorRes[property] + ','
        }

        return resHolder
    }

    const getUserInfo = (token: string) => {
        getRequest('/authentication/info', token, (res: any) => {
            microsoftTeams.authentication.notifySuccess({...res, rememberMe: rememberMe})
        }, (err: AxiosError) => {
            microsoftTeams.authentication.notifyFailure(err.message)
        })
    }

    const handleForm = (e: any) => {
        e.preventDefault()

        if (
            !firstName.length ||
            !lastName.length ||
            !email.length ||
            !pass.length ||
            !isTermsAccepted
        ) {
            setErrorMsg('Fields are required')
            return
        }

        setIsPending(true)

        const body = {
            addinId: 1,
            email: email,
            password: pass,
            confirmPassword: pass,
            firstName: firstName,
            lastName: lastName,
            language: 'en',
            sendstepsEducation: false,
            wordpress_signup: 1,
            freemiumAccount: true,
            termsAccepted: isTermsAccepted ? 1 : 0,
            termsAcceptedTerminology:
                'I accept the Sendsteps Terms of Services, Privacy Statement and License agreement',
            termsAcceptedVersion: '1.18',
        }

        postRequest('/users/signup', body, (res: any) => {
            setIsPending(false)

            if (!res.success) {
                const customErrorMsg: any = res.errors
                    ? handlingErrorResponse(res.errors)
                    : 'Something went wrong!'
                setErrorMsg(customErrorMsg)
                return
            }
            getUserInfo(res.token)

        }, (err: AxiosError) => {
            setIsPending(false)
            microsoftTeams.authentication.notifyFailure(err.message)
        })
    }

    return (
        <Provider theme={teamsTheme}>
            <div className="AuthStart">
                <img src={logoFull} className='AuthStart__header' alt="Sendsteps logo" />
                <Flex column gap="gap.small">
                    {errorMsg && <p>{errorMsg}</p>}

                    <Input
                        label="First Name"
                        type="text"
                        required
                        showSuccessIndicator={false}
                        value={firstName}
                        onChange={(e: any) => setFirstName(e.target.value)}
                    />
                    <Input
                        label="Last Name"
                        type="text"
                        required
                        showSuccessIndicator={false}
                        value={lastName}
                        onChange={(e: any) => setLastName(e.target.value)}
                    />
                    <Input
                        label="Email"
                        type="email"
                        required
                        showSuccessIndicator={false}
                        value={email}
                        onChange={(e: any) => {
                            setEmail(e.target.value)
                        }}
                    />
                    <Input
                        label="Password"
                        type="password"
                        required
                        showSuccessIndicator={false}
                        value={pass}
                        onChange={(e: any) => {
                            setPass(e.target.value)
                        }}
                    />

                    <Checkbox
                        label="I accept the Sendsteps Terms of Services, Privacy Statement and License agreement"
                        checked={isTermsAccepted}
                        onChange={() => setIsTermsAccepted(!isTermsAccepted)}
                    />

                    <Checkbox
                        checked={rememberMe}
                        onChange={() => setRememberMe(!rememberMe)}
                        label='Remember me' />
                        
                    <Button
                        disabled={isPending}
                        content="Log In"
                        fluid
                        loader="Log in"
                        primary
                        onClick={(e: any) => handleForm(e)}
                        type="submit"
                    />
                </Flex>
            </div>
        </Provider>
    )
}

export { AuthRegister }