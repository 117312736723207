import React, { useContext, useEffect, useState } from 'react'
import * as microsoftTeams from '@microsoft/teams-js'
import { AuthStart } from './Auth/AuthStart'
import { SendstepsUserContext } from '../utils/UserContext'
import {getRequest, postRequest} from '../utils/api'
import { AxiosError } from 'axios'

const Config = (props: { context: microsoftTeams.Context }) => {

  const [authSuccess, setAuthSuccess] = useState(false)
  const [meetingExists, setMeetingExists] = useState(false)

  useEffect(() => microsoftTeams.appInitialization.notifySuccess(), [])

  const { sendstepsUser } = useContext(SendstepsUserContext)

  const saveConfig = (url: string) => { 
    microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
      bindMeetingId()
      microsoftTeams.settings.setSettings({
        websiteUrl: url,
        contentUrl: url,
        entityId: 'sendsteps',
        suggestedDisplayName: 'Sendsteps',
      })
      saveEvent.notifySuccess()
    })
  }

  const bindMeetingId = () => {
    if (sendstepsUser){
      const meetingId = props.context.meetingId
      postRequest(`/users/teams-meeting/${meetingId}`, null, () => {
        
      }, (err: AxiosError) => {
        
      }, sendstepsUser.token)
    }
  }

  const checkIfMeetingExists = () => {
    if (sendstepsUser){
      const meetingId = props.context.meetingId
      getRequest(`/users/teams-meeting/${meetingId}`, sendstepsUser.token, () => {
        setMeetingExists(true)
      }, (err: AxiosError) => {
        if(err.response && err.response.status  === 404){
          const url = `${window.location.origin}/channel-dashboard/${props.context.userObjectId}`
          microsoftTeams.settings.setValidityState(true)
          saveConfig(url)
        }
      })
    }
  }

  const userRemembered = () => {
    sendstepsUser && sendstepsUser.rememberMe && setAuthSuccess(true)
  }

  useEffect(checkIfMeetingExists, [authSuccess])
  useEffect(userRemembered, [])

  return (
    <div>
      {
        !authSuccess ?
          <AuthStart optionalSuccessActions={() => setAuthSuccess(true)} /> :
          meetingExists ? 
          <div>
            <h1>{`Hello ${sendstepsUser?.user.firstName}, Sendsteps is already added to this meeting`}</h1>
            <div>
              You can't do this action more than once.
            </div>
          </div> :
          <div>
            <h1>{`Hello ${sendstepsUser?.user.firstName}, you're about to add Sendsteps to this meeting`}</h1>
            <div>
              Select <b>Save</b> to add our sendsteps to your channel.
            </div>
          </div>
      }
    </div>
  )
}

export { Config }
