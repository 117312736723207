import React, { useState } from 'react'
import * as microsoftTeams from '@microsoft/teams-js'
import { Flex, Input, Button, Provider, teamsTheme, Checkbox } from '@fluentui/react-northstar'
import logoFull from '../../assets/sendsteps-logo-full.png'
import { postRequest } from '../../utils/api'
import { AxiosError } from 'axios'

const AuthLogin = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [rememberMe, setRememberMe] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [isPending, setIsPending] = useState(false)

    const handleSubmit = (e: any) => {
        e.preventDefault()

        if (!email.length || !password.length) {
            setErrorMsg('Fields are required')
            return
        }

        setIsPending(true)

        const body = {
            email: email,
            password: password,
            client_id: 'msteams'
        }

        postRequest('/authentication/login', body, (res: any) => {
            setIsPending(false)

            if ( typeof res === 'string') {
                setErrorMsg(res)
            } else if ( res.user.id === undefined ) {
                setErrorMsg('Incorrect email or password.')
            } else {
                microsoftTeams.authentication.notifySuccess({...res, rememberMe: rememberMe})
            }

        }, (err: AxiosError) => {
            setIsPending(false)
            microsoftTeams.authentication.notifyFailure(err.message)
        })
    }

    return (
        <Provider theme={teamsTheme}>
            <div className="AuthStart">
                <img src={logoFull} className='AuthStart__header' alt="Sendsteps logo" />
                <Flex column gap="gap.small">
                    {errorMsg &&
                        <p className='AuthStart__header--message'>
                            {errorMsg}
                            <a className='AuthStart__header--help' target="_blank" href="https://www.sendsteps.com/contact/">Need help? Contact support</a>
                        </p>
                    }
                    <Input
                        label="Email"
                        type="email"
                        required
                        showSuccessIndicator={false}
                        iconPosition="end"
                        value={email}
                        onChange={(e: any) => {
                            setEmail(e.target.value)
                        }}
                    />
                    <Input
                        label="Password"
                        type="password"
                        required
                        showSuccessIndicator={false}
                        value={password}
                        onChange={(e: any) => {
                            setPassword(e.target.value)
                        }}
                    />
                    <Checkbox
                        checked={rememberMe}
                        onChange={() => setRememberMe(!rememberMe)}
                        label='Remember me' />

                    <a target='blank' href={`${process.env.REACT_APP_DASHBOARD_URL}/site/request-password-reset`}>Forgot password?</a>
                    <span
                        data-eltype="button"
                        data-elaction="login"
                        data-elscreen="login"
                        data-elposition="unspecified"
                    >
                        <Button
                            disabled={isPending}
                            content="Log In"
                            fluid
                            loader="Log in"
                            primary
                            onClick={(e) => handleSubmit(e)}
                            type="submit"
                        />
                    </span>
                </Flex>
            </div>
        </Provider>
    )
}

export { AuthLogin }
