import { Button } from '@fluentui/react-northstar'
import React, {useState, useEffect} from 'react'
import {
    VoteAnswerInterface,
    VoteUpdatedInterface,
    VoteResultsInterface,
    VoteResultAnswerInterface,
} from '../../../../utils/Interfaces'

const ParticipantVote = (props: {
        currentVote: VoteUpdatedInterface,
        submitVote(voteId: number): void,
        currentVoteSubmitted: { answers: any[], nrOfVotes: number },
        voteResults: VoteResultsInterface | undefined
    }) => {

    const { currentVote, currentVoteSubmitted, submitVote, voteResults } = props

    const questionTimer = currentVote.data[0].dynamicTimerLimit

    const [timer, setTimer] = useState(questionTimer > 0 ? questionTimer : 0)
    const [timerId, setTimerId] = useState<any>()

    const timerExistAndExpired = questionTimer > 0 && timer === 0

    const voteLimitReached = currentVoteSubmitted.answers.length >= currentVoteSubmitted.nrOfVotes

    const voteLimitCounter = currentVoteSubmitted.nrOfVotes - currentVoteSubmitted.answers.length

    const getUserVotes = () => {
        const votes : string[] = []
            currentVoteSubmitted.answers.forEach((answerId: number, index: number) => {
                const vote = currentVote.answers.find(answer => answer.id === answerId)?.answerCode
                vote !== undefined && votes.push(vote)})
        return votes
    }

    const countVoteAnswerPercentage = (votes?: number) => {
        const totalAnswerCount = voteResults?.responseCount ?? 0
        const submitedVotes = votes ? votes : 0
        const totalVotes = totalAnswerCount ? totalAnswerCount : 0
        return totalVotes !== 0 ? (((submitedVotes / totalVotes) * 100).toFixed(0) + '%') : '0%'
    }

    useEffect(()=>{
        const timerId = setTimeout(()=> timer > 0 && setTimer(timer - 1), 1000)
        setTimerId(timerId)
    },[timer])

    useEffect(()=>{
        clearTimeout(timerId)
        setTimer(questionTimer > 0 ? questionTimer : 0) //eslint-disable-next-line
    },[currentVote])

    return (
        <div className='ParticipantModule__Vote'>
            <h1 className='ParticipantModule__Question'>{currentVote.data[0].title}</h1>
            {
                !voteLimitReached && !timerExistAndExpired ?
                    <div>
                        {questionTimer > 0 && <p>{`Time: ${timer}`}</p>}
                        <div className='ParticipantModule__Answers'>
                            <p>{`You can vote ${voteLimitCounter} time${voteLimitCounter > 1 ? 's' : ''}`}</p>
                            {
                                currentVote.answers.map((answer: VoteAnswerInterface, index: number) =>
                                    <div className='ParticipantModule__Answer' key={index}>
                                        <span
                                            data-eltype="button"
                                            data-elaction="submit_vote"
                                            data-elscreen="side_panel_participant"
                                            data-elposition="participant_vote"
                                        >
                                            <Button
                                                className='ParticipantModule__Button'
                                                onClick={() => submitVote(index)}
                                                primary
                                            >{answer.answer}</Button>
                                        </span>
                                    </div>)
                            }
                        </div>
                    </div>:

                    <div className='ParticipantModule__SubmittedAnswer'>
                        {
                            getUserVotes().length !== 0 ?
                                getUserVotes().map((vote: string, index: number)=>
                                <div>
                                    <p>Your answer : </p>
                                    <p key={index}>{vote + ' '}</p>
                                </div>) :
                                <p>You have no submitted answers</p>
                        }
                    </div>
            }
            { voteResults && getUserVotes().length !== 0 &&
                <div className='ParticipantModule__VoteResults'>
                    {
                        voteResults?.responses?.map((answer: VoteResultAnswerInterface, index: number) =>
                            <div key={index}>
                                <p key={index}
                                    className='ParticipantModule__VoteResults--Answer'>
                                    {`${answer.answerCode}: ${answer.answer}`}</p>
                                <div className='ParticipantModule__VoteResults--Progress'>
                                    <div>
                                        <div
                                            className={answer.correctAnswer ? 'Green' : 'Red'}
                                            style={{ maxWidth: countVoteAnswerPercentage(answer.receivedNrOfVotes ?? 0) }} />
                                    </div>
                                    <p>{
                                        `${countVoteAnswerPercentage(answer.receivedNrOfVotes ?? 0)}
                                                    (${answer.receivedNrOfVotes ? answer.receivedNrOfVotes : 0})`}
                                    </p>
                                </div>
                            </div>)
                    }
                </div>
            }
        </div>
    )
}

export { ParticipantVote }
