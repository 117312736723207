import {Button, Card} from '@fluentui/react-northstar'
import React from 'react'
import {QuizInterface} from '../utils/Interfaces'

interface QuizCardPropsInterface {
    quiz: QuizInterface
    present?: boolean
    startPresentation?: () => void
    removeQuiz?: () => void
    editQuiz?: () => void
}

const QuizCard = (props: QuizCardPropsInterface) => {

    const {quiz} = props

    return (
        <Card
            className='QuizCard'>
            <p className='QuizCard__Title'>{quiz.quizTitle}</p>
            <p className='QuizCard__Questions'>Questions : {quiz.questions}</p>
            {
                props.present ?
                    <span
                        data-eltype="button"
                        data-elaction="start_presentation"
                        data-elscreen="side_panel"
                        data-elposition="presentation_card"
                    >
                        <Button
                            onClick={props.startPresentation}
                            className='QuizCard__Button'
                            primary
                        >
                            Start
                        </Button>
                    </span>
                     :
                    <div className='QuizCard__Buttons'>
                        <span
                            data-eltype="button"
                            data-elaction="edit_presentation"
                            data-elscreen="dashboard"
                            data-elposition="presentation_card"
                        >
                            <Button
                                onClick={() => props.editQuiz && props.editQuiz()}
                                className='QuizCard__Button'
                                primary
                            >
                                Edit
                            </Button>
                        </span>
                        <span
                            data-eltype="button"
                            data-elaction="delete_presentation"
                            data-elscreen="dashboard"
                            data-elposition="presentation_card"
                        >
                            <Button
                                onClick={() => props.removeQuiz && props.removeQuiz()}
                                className='QuizCard__Button'
                                secondary
                            >
                                Delete
                            </Button>
                        </span>
                    </div>
            }
        </Card>
    )

}

export {QuizCard}
