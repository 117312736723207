import { Slider } from '@fluentui/react-northstar'
import React from 'react'

const NrOfVotes = (props: { nrOfVotes: number, setNrOfVotes(nrOfVotes: number): void }) => {

    const { nrOfVotes } = props

    return (
        <div className='CreateQuizModal__NrOfVotes'>
            <p>Amount of times participants can respond</p>
            <Slider min='1' max='15'
                value={nrOfVotes}
                step='1'
                onChange={(e, data) => data && props.setNrOfVotes(Number(data.value))} />
            <p>{nrOfVotes}</p>
        </div>
    )
}

export { NrOfVotes }