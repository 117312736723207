import { Slider } from '@fluentui/react-northstar'
import React from 'react'

const NrOfMessagesAllowed = (props: { nrOfMessagesAllowed: number, setNrOfMessagesAllowed(nrOfMessagesAllowed: number): void }) => {

    const { nrOfMessagesAllowed } = props

    return (
        <div className='CreateQuizModal__NrOfMessagesAllowed' >
            <p>Number of audience responses allowed per participant</p>
            <Slider min='1' max='16'
                value={nrOfMessagesAllowed !== 0 ? nrOfMessagesAllowed : 16}
                step='1'
                onChange={(e, data) => data && props.setNrOfMessagesAllowed(Number(data.value) !== 16 ? Number(data.value) : 0)} />
            <p>{nrOfMessagesAllowed !== 0 ? nrOfMessagesAllowed : '∞'}</p>
        </div >
    )
}

export { NrOfMessagesAllowed }