import React, { useContext } from 'react'
import { Button } from '@fluentui/react-northstar'
import logoFull from '../../assets/sendsteps-logo-full.png'
import { SendstepsUserContext } from '../../utils/UserContext'
import * as microsoftTeams from '@microsoft/teams-js'

const AuthStart = (props: { optionalSuccessActions?: () => void }) => {

    const { updateSendstepsUser } = useContext(SendstepsUserContext)

    const loginAccount = () => {
        microsoftTeams.authentication.authenticate({
            url: `${window.location.origin}/login`,
            width: 600,
            height: 535,
            successCallback: function (result: any) {
                updateSendstepsUser(result)
                props.optionalSuccessActions && props.optionalSuccessActions()
            },
            failureCallback: function (reason) {
                console.log('failureCallback loginAccount', reason)
            },
        })
    }

    const registerAcc = () => {
        microsoftTeams.authentication.authenticate({
            url: `${window.location.origin}/register`,
            width: 600,
            height: 535,
            successCallback: function (result: any) {
                updateSendstepsUser(result)
                props.optionalSuccessActions && props.optionalSuccessActions()
            },
            failureCallback: function (reason) {
                console.log('failureCallback loginAccount', reason)
            },
        })
    }

    return (
        <div className="Auth">
            <img src={logoFull} alt="Sendsteps logo" />
            <h3>Welcome to Sendsteps.</h3>
            <p className="desc">
                Use Sendsteps as your Audience Response System to vote and ask
                questions during a Presentation.
          </p>
            <div className="container">
                <Button
                    onClick={loginAccount}
                    content="Log In"
                    loader="Override pixel"
                    primary
                />
                <Button
                    onClick={registerAcc}
                    content="Register"
                    loader="Override pixel"
                />
            </div>
        </div>
    )
}

export { AuthStart }