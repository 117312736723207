import { Menu } from '@fluentui/react-northstar'
import React from 'react'

const PointsAwarded = (props: { points: number, setPoints(points: number): void }) => {

    return (
        <div className='CreateQuizModal__Points'>
            <p>Points Awarded</p>
            <Menu
                primary
                className='PointsMenu'
                onActiveIndexChange={(e, data) => data && props.setPoints(Number(data?.activeIndex))}
                activeIndex={props.points}>
                <Menu.Item index={500}>
                    <Menu.ItemContent>500 Points</Menu.ItemContent>
                </Menu.Item>
                <Menu.Item index={1000}>
                    <Menu.ItemContent>1000 Points</Menu.ItemContent>
                </Menu.Item>
            </Menu>
        </div>
    )
}

export { PointsAwarded }