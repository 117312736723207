import React, {useContext, useEffect, useState} from 'react'
import {getRequest} from '../../../utils/api'
import {QuizCard} from '../../QuizCard'
import {QuizInterface} from '../../../utils/Interfaces'
import {SendstepsUserContext} from '../../../utils/UserContext'
import {Button} from '@fluentui/react-northstar'
import * as microsoftTeams from "@microsoft/teams-js"
import {AuthStart} from '../../Auth/AuthStart'
import GoogleTagManager from '../../GoogleTagManager'

const PresenterModule = (props: { startPresentation: (quizId: number) => void }) => {

    const {sendstepsUser} = useContext(SendstepsUserContext)
    const token = sendstepsUser ? sendstepsUser.token : ''
    const firstName = sendstepsUser ? sendstepsUser.user.firstName : 'User'
    const [quizes, setQuizes] = useState<QuizInterface[]>([])

    const getQuizes = () => {
        getRequest('/quiz/quizlist', token, (response: QuizInterface[]) => {
            setQuizes(response.reverse())
        }, () => {

        })
    }

    const createQuiz = {
        title: 'Create Sendsteps',
        height: 700,
        width: 1000,
        url: `${window.location.origin}/create-quiz`,
    }

    const showResults = () => {
        const url = process.env.REACT_APP_DASHBOARD_URL + '/presentations';
        window.open(url, '_blank');
    }

    useEffect(getQuizes, [sendstepsUser])

    useEffect( () => {
        GoogleTagManager()
    }, [])

    return (
        sendstepsUser ?
            <div className='PresenterModule'>
                <div className='PresenterModule__Title'>
                    <p>Hello there, {firstName}</p>
                    <p>You are about to start a presentation, Select one of your available quizes or create new one</p>
                </div>
                <span
                    data-eltype="button"
                    data-elaction="create_presentation"
                    data-elscreen="side_panel_presenter"
                    data-elposition="initial_screen"
                >
                    <Button
                        onClick={() => microsoftTeams.tasks.startTask(createQuiz, getQuizes)}
                        className='PresenterModule__CreateButton'
                        primary
                    >
                        Create new Sendsteps
                    </Button>
                </span>
                <p></p>
                <span
                    data-eltype="button"
                    data-elaction="presentation_results"
                    data-elscreen="side_panel_presenter"
                    data-elposition="initial_screen"
                >
                    <Button
                        onClick={showResults}
                        className='PresenterModule__ResultsButton'
                        secondary
                    >
                        Previous results
                    </Button>
                </span>

                <div className='PresenterModule__Quizes'>
                    {
                        quizes ?
                            quizes.map((quiz: QuizInterface, index: number) =>
                                <QuizCard quiz={quiz} key={index} present={true}
                                          startPresentation={() => props.startPresentation(quiz.id)}/>) :
                            <p>You currently have no available presentations</p>
                    }
                </div>
            </div> :
            <AuthStart/>
    )
}

export {PresenterModule}
