import { mdiFormatListChecks } from '@mdi/js'
import React from 'react'
import { CreateVoteInterface, OptionInterface, VoteErrorInterface } from '../../../utils/Interfaces'
import { Answers } from '../Fields/Answers'
import { DividerGroup } from '../Fields/DividerGroup'
import { Errors } from '../Fields/Errors'
import { NrOfVotes } from '../Fields/NrOfVotes'
import { QuestionLabel } from '../Fields/QuestionLabel'
import { TimeControl } from '../Fields/TimeControl'

const MultipleChoice = (props: { multipleChoice: CreateVoteInterface, voteErrors: VoteErrorInterface | undefined, index: number, updateVote(multipleChoice: CreateVoteInterface | undefined): void }) => {

    const { multipleChoice, voteErrors, updateVote } = props

    return (
        <div className='CreateQuizModal__Vote'>
            <DividerGroup index={props.index} deleteVote={() => updateVote(undefined)} iconPath={mdiFormatListChecks} />
            {voteErrors && <Errors voteErrors={voteErrors}/>}
            <QuestionLabel value={multipleChoice.title} updateVoteTitle={(value: string) => updateVote({ ...multipleChoice, title: value })} />
            <Answers options={multipleChoice.options} hideCorrectAnswer={true}
                    setOptions={(options: OptionInterface[]) => updateVote({ ...multipleChoice, options: options })} />
            <TimeControl
                timerLimit={multipleChoice.dynamicTimerLimit}
                setTimer={(timerLimit: number, counterPresent: number) => updateVote({ ...multipleChoice, dynamicTimerLimit: timerLimit, votesCounterPresent: counterPresent })} />
            <NrOfVotes nrOfVotes={multipleChoice.nrOfVotes} setNrOfVotes={(nrOfVotes: number)=>props.updateVote({...multipleChoice, nrOfVotes: nrOfVotes})}/>
        </div>
    )
}

export { MultipleChoice }