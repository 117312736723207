import {
    ArrowLeftIcon,
    ArrowRightIcon,
    Button,
    CallControlStopPresentingNewIcon,
    SpeakerPersonIcon
} from '@fluentui/react-northstar'
import {AxiosError} from 'axios'
import * as microsoftTeams from "@microsoft/teams-js"
import React, {useContext, useEffect, useState} from 'react'
import {getRequest, postRequest, sendshakeRequest} from '../../../utils/api'
import {
    MessageroundInterface,
    QuizDetailsInterface,
    VoteAnswerInterface,
    VoteInterface
} from '../../../utils/Interfaces'
import {SendstepsUserContext} from '../../../utils/UserContext'
import {PresenterVote} from './QuestionViews/PresenterVote'
import {PresenterMessageround} from './QuestionViews/PresenterMessageround'

interface StartedSessionInterface {
    quizId: number
    sessionData: {
        sessionId: string
        sessionRunId: string
        responseCode: string
    }
}

interface CurrentQuestionInterface {
    id: string
    question: VoteInterface | MessageroundInterface
    answers?: VoteAnswerInterface[]
}

const PresenterQuiz = (props: { stopQuiz: () => void, sessionData: StartedSessionInterface }) => {

    const [quiz, setQuiz] = useState<QuizDetailsInterface>()
    const [questions, setQuestions] = useState<(VoteInterface | MessageroundInterface)[]>([])
    const [slide, setSlide] = useState(0)
    const [loadingFinished, setLoadingFinished] = useState(false)
    const [loadingError, setLoadingError] = useState(false)
    const [currentQuestion, setCurrentQuestion] = useState<CurrentQuestionInterface>()
    const [results, setResults] = useState([])
    const [resultsShared, setResultsShared] = useState(false)
    const [participantCount, setParticipantCount] = useState(0)
    //this state is buffer for submited answers of participants => voteMessage:created
    const [submittedAnswer, setSubmittedAnswer] = useState<string[]>([])
    const [timer, setTimer] = useState(-1)
    const [timerId, setTimerId] = useState<any>()
    const [scoreboardOpened, setScoreboardOpened] = useState(false)
    const [showPodium, setShowPodium] = useState(false)
    const [scoreboardSharing, setScoreboardSharing ] = useState(false)

    const {sessionData, quizId} = props.sessionData
    const {sendstepsUser} = useContext(SendstepsUserContext)
    const token = sendstepsUser ? sendstepsUser.token : ''

    const SHARE_RESULTS_TIMER = 4000

    useEffect(()=>{
        !showPodium && scoreboardOpened && setShowPodium(true)
    }, [scoreboardOpened, showPodium])

    useEffect(() => {
            if (timer > 0) {
                const timeoutId = setTimeout(() => {
                    setTimer(timer - 1)
                }, 1000)
                setTimerId(timeoutId)
            } else if (timer === 0) {
                currentQuestion && closeVote(Number(currentQuestion.id))
            }
        } //eslint-disable-next-line
        , [timer])

    useEffect(() => {
        scoreboardOpened && setScoreboardOpened(false)
        if (currentQuestion && ('isQuizVote' in currentQuestion.question)) {
            if (currentQuestion.question.dynamicTimerLimit > 0) {
                timerId && clearTimeout(timerId)
                setTimer(currentQuestion.question.dynamicTimerLimit)
            }
        }
        //eslint-disable-next-line
    }, [currentQuestion?.id])

    useEffect(() => {
        if (scoreboardSharing) {
            emitResultsToParticipants()
            const intervalId = setInterval(() => {
                emitResultsToParticipants()
            }, SHARE_RESULTS_TIMER)
            return () => clearInterval(intervalId)
        }
        return undefined
    }, [scoreboardSharing])

    const io = require('socket.io-client')

    const getQuiz = (quizId: number) => {
        getRequest(`/quiz/details/${quizId}`, token, (res: QuizDetailsInterface) => {
            setQuiz(res)
            const voteIds = Object.keys(res.votes)
            const votes = voteIds.map((voteId: string) => res.votes[Number(voteId)])
            const messageroundIds = Object.keys(res.messagerounds)
            const messagerounds = messageroundIds.map((messageroundId: string) => res.messagerounds[Number(messageroundId)])
            setQuestions([...votes, ...messagerounds].sort((a, b) => a.slideIndex > b.slideIndex ? 1 : -1))
        }, (error: AxiosError) => {
            //Error handling
        })
    }

    const initSocket = () => {
        const socket = io(process.env.REACT_APP_SOCKET_URL, {
            reconnection: true,
            reconnectionDelay: 2000,
            reconnectionDelayMax: 5000,
            timeout: 20e3,
            reconnectionAttempts: 999,
            transports: ['websocket']
        })

        socket.on('connect', () => {
            socket.emit('session:subscribe', {socketId: socket.id, sessionId: sessionData.sessionId, token: token})
        })

        socket.on('session:subscribed', (data: { participants: { socketId: string }[] }) => setParticipantCount(data.participants.length))

        socket.on('participant:joined', () => { setParticipantCount(prev => prev + 1)} )

        socket.on('participant:logged_out', () => setParticipantCount(prev => prev - 1))

        socket.on('voteMessage:created', (data: any) => setSubmittedAnswer([...submittedAnswer, data.data[0].text]))

        socket.on('message:created', (data: any) => setSubmittedAnswer([...submittedAnswer, data.data[0].text]))

        return () => socket.disconnect()
    }

    const clearResults = () => {

        //clears results in database
        postRequest('/quiz/clear-results', `presentationId=${quizId}`, (res: { newPresentationId: number }) => {
            getQuiz(res.newPresentationId)
        }, () => {
            setLoadingError(true)
        }, token)

        //notify participants to clear cached answered votes
        const sendshakeData = {
            request: encodeURI('ClearResults'),
            presentation_id: encodeURI(quizId.toString()),
            token: encodeURI(token)
        }
        sendshakeRequest(sendshakeData, (res: string[]) => {

        }, () => {
            setLoadingError(true)
        })
    }

    const setSessionRunId = () => {
        if (quiz) {
            const body = {
                presentationId: quiz?.presentation.id,
                sessionRunId: sessionData.sessionRunId
            }
            postRequest('/quiz/set-presentation-sessionrun-id', body, (res: any) => {

            }, (err: AxiosError) => {
                setLoadingError(true)
            }, token)
        }
    }

    const addVotesToTheSession = () => {
        if (quiz) {
            const votes = Object.keys(quiz.votes).map((key: string) => ({
                vote_id: encodeURI(quiz.votes[Number(key)].id.toString()),
                slide_index: encodeURI(quiz.votes[Number(key)].slideIndex.toString())
            }))
            const sendshakeData = {
                request: encodeURI('AddVotesToSessionV2'),
                presentation_id: encodeURI(String(quiz.presentation.id)),
                session_id: encodeURI(sessionData.sessionId),
                votes: votes,
                token: encodeURI(token),
            }
            sendshakeRequest(sendshakeData, (res: any) => {
                addMessageroundsToTheSession()
            }, (err: any) => {
                setLoadingError(true)
            })
        }
    }

    const addMessageroundsToTheSession = () => {
        if (quiz) {
            const messagerounds = Object.keys(quiz.messagerounds).map((key: string) => ({
                message_round_id: encodeURI(quiz.messagerounds[Number(key)].id.toString()),
                slide_index: encodeURI(quiz.messagerounds[Number(key)].slideIndex.toString())
            }))
            const sendshakeData = {
                request: encodeURI('AddMessageRoundsToSessionV2'),
                presentation_id: encodeURI(String(quiz.presentation.id)),
                session_id: encodeURI(sessionData.sessionId),
                message_rounds: messagerounds,
                token: encodeURI(token),
            }
            sendshakeRequest(sendshakeData, (res: any) => {
                setLoadingFinished(true)
            }, (err: any) => {
                setLoadingError(true)
            })
        }
    }

    const openQuestion = (action: string) => {
        const slideIndex = action === 'next' ? slide + 1 : slide - 1
        currentQuestion && ('isQuizVote' in currentQuestion.question ? closeVote(Number(currentQuestion.id)) : closeMessageround(Number(currentQuestion.id)))

        if (questions) {
            const slideQuestion = questions[slideIndex - 1]

            const isFinished = questions.length <= (slideIndex - 1)

            if (action === 'next'
                && currentQuestion && 'isQuizVote' in currentQuestion.question
                && currentQuestion.question.isQuizVote === 1
                && !scoreboardOpened) {
                getResults()
            } else if (slideIndex !== 0) {
                if (!isFinished) {
                    if ('isQuizVote' in slideQuestion) {
                        openVote(slideQuestion, slideIndex)
                    } else openMessageround(slideQuestion, slideIndex)
                } else {
                    setSlide(questions.length + 1)
                    showPodium && getResults()
                }
            } else {
                setCurrentQuestion(undefined)
                setSlide(0)
            }
        }
    }

    const openMessageround = (messageround: MessageroundInterface, slideIndex: number) => {
        const sendshakeData = {
            request: encodeURI('OpenMessageRound'),
            message_round_id: encodeURI(messageround.id.toString()),
            token: encodeURI(token)
        }
        sendshakeRequest(sendshakeData, (res: any) => {
            setSlide(slideIndex)
            setCurrentQuestion({
                id: messageround.id.toString(),
                question: messageround
            })
        }, () => {

        })
    }

    const closeMessageround = (messageroundId: number) => {
        const sendshakeData = {
            request: encodeURI('CloseMessageRound'),
            message_round_id: encodeURI(messageroundId.toString()),
            token: encodeURI(token)
        }
        sendshakeRequest(sendshakeData, (res: any) => {

        }, () => {

        })
    }

    const openVote = (vote: VoteInterface, slideIndex: number) => {
        const sendshakeData = {
            request: encodeURI('OpenVote'),
            vote_id: encodeURI(vote.id.toString()),
            token: encodeURI(token)
        }
        sendshakeRequest(sendshakeData, (res: any) => {
            setResultsShared(false)
            setSlide(slideIndex)
            setCurrentQuestion({
                id: vote.id.toString(),
                question: vote,
                answers: quiz?.voteAnswers[vote.id],
            })
        }, () => {

        })
    }

    const closeVote = (voteId: number) => {

        setScoreboardSharing(false)

        const sendshakeData = {
            request: encodeURI('CloseVote'),
            vote_id: encodeURI(voteId.toString()),
            token: encodeURI(token)
        }
        sendshakeRequest(sendshakeData, (res: any) => {

        }, () => {

        })
    }

    const getResults = () => {
        if (quiz) {
            const voteIds = Object.keys(quiz.votes)
            const body = {
                voteIds: voteIds,
                sessionRunId: sessionData.sessionRunId,
                currentVoteId: voteIds[voteIds.length - 1]
            }
            postRequest('/quiz/broadcast-scores', body, (res: any) => {
                setResults(res.raw.scores)
                setScoreboardOpened(true)
            }, () => {

            }, token)
        }
    }

    const stopPresentation = () => {

        if (currentQuestion) {
            'isQuizVote' in currentQuestion.question ?
                closeVote(Number(currentQuestion.id)) :
                closeMessageround(Number(currentQuestion.id))
        }
        const sendshakeData = {
            request: encodeURI('StopSession'),
            session_id: encodeURI(sessionData.sessionId),
            session_run_id: encodeURI(sessionData.sessionRunId),
            email_presentation_results: encodeURI('1'),
            token: encodeURI(token)
        }
        sendshakeRequest(sendshakeData, (res: any) => {
        }, () => {

        })
        props.stopQuiz()
    }

    const onVoteAnswered = () => {
        if (submittedAnswer && currentQuestion && ('isQuizVote' in currentQuestion.question)) {
            const lastestSubmittedAnswer = submittedAnswer[submittedAnswer.length - 1]
            let currentQuestionBuffer = {...currentQuestion}
            const answer = currentQuestionBuffer?.answers?.find(answer => answer.answerCode === lastestSubmittedAnswer)
            if (answer) {
                let currentVote = currentQuestionBuffer.question
                answer.submittedAnswers ? answer.submittedAnswers++ : answer.submittedAnswers = 1
                typeof (currentVote.submittedAnswers) === 'number' && currentVote.submittedAnswers ? currentVote.submittedAnswers++ : currentVote.submittedAnswers = 1
                setCurrentQuestion(currentQuestionBuffer)
            }
        } else if (submittedAnswer && currentQuestion && ('hasUpvoting' in currentQuestion.question && !currentQuestion.question.submittedAnswers?.includes(submittedAnswer[submittedAnswer.length - 1]))) {
            let currentQuestionBuffer = {...currentQuestion}
            let submittedAnswers = currentQuestionBuffer.question.submittedAnswers
            const lastestSubmittedAnswer = submittedAnswer[submittedAnswer.length - 1]
            submittedAnswers = submittedAnswers ?
                (typeof submittedAnswers !== 'number') ? [...submittedAnswers, lastestSubmittedAnswer] : [lastestSubmittedAnswer] :
                [lastestSubmittedAnswer]
            currentQuestionBuffer.question.submittedAnswers = submittedAnswers
            setCurrentQuestion(currentQuestionBuffer)
        }
    }

    const emitResultsToParticipants = () => {
        if ( !currentQuestion?.id ) {
            return
        }

        const sendshakeData = {
            request: encodeURI('CalculateVoteResults'),
            vote_id: Number(currentQuestion.id),
            token: encodeURI(token),
        }
        sendshakeRequest(sendshakeData, (res: any) => {
            setResultsShared(true)
        }, (err: any) => {
            setLoadingError(true)
        })
    }

    const removeLoadingIndicator = () => {
        !loadingError && microsoftTeams.appInitialization.notifySuccess()
    }

    useEffect(initSocket, [])
    useEffect(clearResults, [])
    useEffect(setSessionRunId, [quiz])
    useEffect(addVotesToTheSession, [quiz])
    useEffect(removeLoadingIndicator, [loadingFinished])

    useEffect(onVoteAnswered, [submittedAnswer])

    return (
        <div className='PresenterQuiz'>
            {
                slide === 0 ?
                    <div className='PresenterQuiz__Start'>
                        <p>All set!</p>
                        <p>{`Code: ${sessionData.responseCode}`}</p>
                        <span
                            data-eltype="button"
                            data-elaction="presentation_start"
                            data-elscreen="side_panel_presenter"
                            data-elposition="unspecified"
                        >
                            <Button
                                className='PresenterStartButton'
                                primary
                                onClick={() => openQuestion('next')}
                            >Start</Button>
                        </span>
                    </div> :
                    scoreboardOpened ?
                        <div className='PresenterQuiz__Scores'>
                            <div className='PresenterQuiz__Score'>
                                <p>Nickname</p>
                                <p>Points</p>
                            </div>
                            {
                                results && results.map((score: any, index: number) =>
                                    <div key={index} className='PresenterQuiz__Score'>
                                        <p>{`${index + 1}. ${score.name}`}</p>
                                        <p>{`${score.points}`}</p>
                                    </div>)
                            }
                        </div> :
                        currentQuestion &&
                        <div className='PresenterQuiz__Question'>
                            <p className='PresenterQuiz__Question--Title'>{currentQuestion.question.title}</p>
                            {
                                ('isQuizVote' in currentQuestion.question) && currentQuestion.answers ?
                                    <div>
                                        {
                                            currentQuestion.question.dynamicTimerLimit > 0 &&
                                            <p>Time: <b>{timer}</b></p>
                                        }
                                        <PresenterVote
                                            answers={currentQuestion.answers}
                                            totalAnswerCount={currentQuestion.question.submittedAnswers}
                                        />
                                        {currentQuestion.question.submittedAnswers &&
                                            <span
                                                data-eltype="button"
                                                data-elaction="share_results"
                                                data-elscreen="side_panel_presenter"
                                                data-elposition="presenter_messageround"
                                            >
                                                <Button
                                                    primary
                                                    className='PresenterQuiz__Question--Button'
                                                    onClick={() => setScoreboardSharing(true)}
                                                >
                                                    { resultsShared ? 'Results are shared!': 'Share results with participants' }
                                                </Button>
                                            </span>
                                        }
                                    </div> :
                                    ('hasUpvoting' in currentQuestion.question) && <PresenterMessageround
                                        submittedMessagerounds={currentQuestion.question.submittedAnswers}/>

                            }
                        </div>

            }
            <div className='PresenterQuiz__FixedFooter'>
                <span
                    data-eltype="button"
                    data-elaction="presentation_previous"
                    data-elscreen="side_panel_presenter"
                    data-elposition="footer"
                >
                    <Button
                        disabled={slide === 0}
                        circular
                        className='PresenterQuiz__FixedFooter--Button'
                        onClick={() => openQuestion('back')}
                    ><ArrowLeftIcon/></Button>
                </span>
                <span
                    data-eltype="button"
                    data-elaction="presentation_next"
                    data-elscreen="side_panel_presenter"
                    data-elposition="footer"
                >
                    <Button
                        circular
                        className='PresenterQuiz__FixedFooter--Button'
                        onClick={() => openQuestion('next')}
                    ><ArrowRightIcon/></Button>
                </span>
                <span
                    data-eltype="button"
                    data-elaction="presentation_stop"
                    data-elscreen="side_panel_presenter"
                    data-elposition="footer"
                >
                    <Button
                        circular
                        className='PresenterQuiz__FixedFooter--Button'
                        onClick={() => {
                            stopPresentation()
                        }}
                    ><CallControlStopPresentingNewIcon/></Button>
                </span>

                <div className='PresenterQuiz__FixedFooter--ParticipantCounter'>
                    <SpeakerPersonIcon size='large'/>
                    <p>{participantCount}</p>
                </div>
            </div>
        </div>
    )
}

export {PresenterQuiz}
