import { mdiTrophy } from '@mdi/js'
import React from 'react'
import { CreateVoteInterface, OptionInterface, VoteErrorInterface } from '../../../utils/Interfaces'
import { Answers } from '../Fields/Answers'
import { DividerGroup } from '../Fields/DividerGroup'
import { Errors } from '../Fields/Errors'
import { PointsAwarded } from '../Fields/PointsAwarded'
import { QuestionLabel } from '../Fields/QuestionLabel'
import { TimeControl } from '../Fields/TimeControl'

const Quiz = (props: { quiz: CreateVoteInterface, voteErrors: VoteErrorInterface | undefined, index: number, updateVote(quiz: CreateVoteInterface | undefined): void }) => {

    const { quiz, voteErrors, updateVote } = props

    return (
        <div className='CreateQuizModal__Vote'>
            <DividerGroup index={props.index} deleteVote={() => updateVote(undefined)} iconPath={mdiTrophy} />
            {voteErrors && <Errors voteErrors={voteErrors}/>}
            <QuestionLabel value={quiz.title} updateVoteTitle={(value: string) => updateVote({ ...quiz, title: value })} />
            <Answers options={quiz.options} setOptions={(options: OptionInterface[]) => updateVote({ ...quiz, options: options })} />
            <PointsAwarded points={quiz.points} setPoints={(points: number) => updateVote({ ...quiz, points: points })} />
            <TimeControl
                timerLimit={quiz.dynamicTimerLimit}
                setTimer={(timerLimit: number, counterPresent: number) => updateVote({ ...quiz, dynamicTimerLimit: timerLimit, votesCounterPresent: counterPresent })} />
        </div>
    )
}

export { Quiz }