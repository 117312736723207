import { ChatIcon } from '@fluentui/react-northstar'
import React from 'react'

const PresenterMessageround = (props: { submittedMessagerounds: string[] | undefined }) => {

    const { submittedMessagerounds } = props

    return (
        <div>
            {
                submittedMessagerounds ?
                    submittedMessagerounds.map((answer: string, index: number) => <p key={index}>{answer}</p>) :
                    <div className='PresenterQuiz__EmptyState'>
                        <ChatIcon size='larger'/>
                        <p>There are currently no submitted answers</p>
                    </div>
            }
        </div>
    )
}

export { PresenterMessageround }