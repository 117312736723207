import { Slider } from '@fluentui/react-northstar'
import React from 'react'

const TimeControl = (props: { timerLimit: number, setTimer(timerLimit: number, counterPresent: number): void }) => {

    const { timerLimit } = props

    return (
        <div className='CreateQuizModal__TimeControl'>
            <p>Answer time (Seconds)</p>
            <Slider min='5' max='60'
                value={timerLimit}
                step='5'
                onChange={(e, data) => data && props.setTimer(Number(data.value), Number(data.value) > 5 ? 1 : 0)} />
            <p>{timerLimit > 5 ? timerLimit : 'OFF'}</p>
        </div>
    )
}

export { TimeControl }