import React from 'react'
import { VoteAnswerInterface } from '../../../../utils/Interfaces'

const PresenterVote = (props: { answers: VoteAnswerInterface[], totalAnswerCount?: number }) => {

    const { answers, totalAnswerCount } = props

    const countVoteAnswerPercentage = (votes?: number) => {
        const submitedVotes = votes ? votes : 0
        const totalVotes = totalAnswerCount ? totalAnswerCount : 0
        return totalVotes !== 0 ? (((submitedVotes / totalVotes) * 100).toFixed(0) + '%') : '0%'
    }

    return (
        <div>
            {
                answers?.map((answer: VoteAnswerInterface, index: number) =>
                    <div key={index}>
                        <p key={index}
                            className='PresenterQuiz__Question--Answer'>
                            {`${answer.answerCode}: ${answer.answer}`}</p>
                        <div className='PresenterQuiz__Progress'>
                            <div>
                                <div
                                    className={answer.correctAnswer ? 'Green' : 'Red'}
                                    style={{ maxWidth: countVoteAnswerPercentage(answer.submittedAnswers) }} />
                            </div>
                            <p>{
                                `${countVoteAnswerPercentage(answer.submittedAnswers)}
                                            (${answer.submittedAnswers ? answer.submittedAnswers : 0})`}
                            </p>
                        </div>
                    </div>)
            }
        </div>
    )
}

export { PresenterVote }
