import Axios from "axios"

const sendstepsApi = process.env.REACT_APP_API_URL

const defaultHeaders : any = (token: string) => ({
    'Authorization': 'Bearer ' + token,
})

const postRequest = (url: string, body: any, onSuccess: any, onError: any, token?: string) => {
    //api param conditionaly calls binds right url, participant api only has post routes

    const headers = defaultHeaders(token ? token : '')

    Axios.post(sendstepsApi + url, body, {headers : headers}).then((res) => {
        res.status === 200 && onSuccess(res.data, res.headers)
    }).catch(error => onError(error))
}

const getRequest = (url: string, token: string, onSuccess: any, onError: any) => {
    Axios.get(sendstepsApi + url, { headers: defaultHeaders(token) }).then((res) => {
        res.status === 200 && onSuccess(res.data)
    }).catch(error => onError(error))
}

const sendshakeRequest = (data: any, onSuccess: any, onError: any) => {
    const sendshakeUrl = `${process.env.REACT_APP_SENDSHAKE_URL}/query?data=`

    Axios.get(sendshakeUrl + JSON.stringify(data)).then((res) => {
        const parsedData = parseSendshakeResponse(res.data)
        onSuccess(parsedData)

    }).catch(error => onError(error))


}

const parseSendshakeResponse = (data: string) => {
    const parsedResponse = data.split('-')
    return parsedResponse.map((value : string, index : number) => index !== 0 ? atob(value) : value)
}

export { postRequest, getRequest, sendshakeRequest }