import React from 'react'
import { Button, ChevronDownIcon, Flex, Popup } from '@fluentui/react-northstar'
import { SendstepsUserInterface } from '../utils/Interfaces'

const Header = (props: { user: SendstepsUserInterface, logout: () => void }) => {

    const sendstepsLogo = require('../assets/sendsteps-logo-full.png')

    const { user } = props.user
    const fullname = user ? `${user.firstName} ${user.lastName}` : 'Unknown'

    const userInfoPopup = () => {
        return (
            <div className='Header__UserInfoPopup'>
                <span
                    data-eltype="button"
                    data-elaction="logout"
                    data-elscreen="dashboard"
                    data-elposition="header"
                >
                    <Button
                        onClick={() => props.logout()}
                    >Sign out</Button>
                </span>
            </div>
        )
    }

    const userInfo = () => {
        return (
            <div className='Header__UserInfo'>
                <p>{fullname}</p>
                <ChevronDownIcon />
            </div>
        )
    }

    return (
        <Flex
            space='between'
            className='Header'>
            <img src={sendstepsLogo} alt='Header' />
            <Popup content={userInfoPopup()} trigger={userInfo()} />
        </Flex>
    )
}

export { Header }
