import TagManager from 'react-gtm-module';

const GoogleTagManager = () => {

    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || ''
	}

    TagManager.initialize(tagManagerArgs);
}

export default GoogleTagManager;