import React, {Component} from 'react'
import { SendstepsUserInterface } from './Interfaces'

interface UserContextInitialDataInterface {
    sendstepsUser?: SendstepsUserInterface
    updateSendstepsUser(user : SendstepsUserInterface | undefined): void
}

const timestampExipired = (timestamp : number) => timestamp < (new Date().getTime() / 1000)

let sendstepsUserLocal : SendstepsUserInterface | undefined

const localData = localStorage.getItem('sendstepsUser')
if(localData){
    const parsedLocalData = JSON.parse(localData)
    timestampExipired(parsedLocalData.expires) ? 
        localStorage.removeItem('sendstepsUser') : 
        sendstepsUserLocal = parsedLocalData
}

const initialData : UserContextInitialDataInterface = {
    sendstepsUser: sendstepsUserLocal,
    updateSendstepsUser: (user : SendstepsUserInterface) => {}
}

export const SendstepsUserContext = React.createContext<UserContextInitialDataInterface>(initialData)

class SendstepsUserProvider extends Component {

    updateSendstepsUser = (user : SendstepsUserInterface | undefined) => {
        if(user){
            this.setState({sendstepsUser: {...user}})
            localStorage.setItem('sendstepsUser', JSON.stringify(user))
        } else {
            this.setState({sendstepsUser: undefined})
            localStorage.removeItem('sendstepsUser')
        }
    }

    state : UserContextInitialDataInterface = {
        sendstepsUser : initialData.sendstepsUser,
        updateSendstepsUser : this.updateSendstepsUser
    }

    render() {
        return(
            <SendstepsUserContext.Provider value={this.state}>
                {this.props.children}
            </SendstepsUserContext.Provider>
        )
    }
}

export default SendstepsUserProvider